<template>
  <div class="p-6 max-w-md w-full bg-white shadow-md rounded-md">
    <h2 class="text-xl leading-9 font-extrabold text-gray-900">
      Sign in to your account
    </h2>
    <div class="mt-4 bg-red-600 text-white rounded-md px-4 py-2" v-if="error">
      Invalid user credentials, please try again.
    </div>
    <div class="h-full mt-4 flex flex-col space-y-10 text-left">
      <form v-on:submit.prevent="login">
        <div>
          <label
            for="email"
            class="block text-sm font-medium leading-5 text-gray-900"
          >
            Email address
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              id="email"
              type="email"
              required
              autocomplete="email"
              class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-gray focus:border-gray-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              :class="{
                'border-red-300 text-red-900 shadow-outline-red focus:shadow-outline-red focus:border-red-300': error,
              }"
              v-model="email"
            />
            <div
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              v-show="error"
            >
              <svg
                class="h-5 w-5 text-red-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <label
            for="password"
            class="block text-sm font-medium leading-5 text-gray-900"
          >
            Password
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              id="password"
              type="password"
              required
              autocomplete="current-password"
              class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-gray focus:border-gray-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              :class="{
                'border-red-300 text-red-900 shadow-outline-red focus:shadow-outline-red focus:border-red-300': error,
              }"
              v-model="password"
            />
            <div
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              v-show="error"
            >
              <svg
                class="h-5 w-5 text-red-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
        </div>

        <div class="mt-4 flex items-center justify-between">
          <div class="flex items-center">
            <input
              id="remember_me"
              type="checkbox"
              class="form-checkbox rounded text-gray-600 transition duration-150 ease-in-out focus:outline-none focus:shadow-outline-gray focus:border-gray-300 focus:outline-none"
              v-model="remember"
            />
            <label
              for="remember_me"
              class="ml-2 block text-sm leading-5 text-gray-900"
            >
              Remember me
            </label>
          </div>

          <div class="text-sm leading-5">
            <router-link
              :to="{ name: 'AuthForgotPassword' }"
              class="font-medium text-gray-800 hover:text-gray-600 focus:outline-none focus:underline transition ease-in-out duration-150"
              >Forgot your password?</router-link
            >
          </div>
        </div>

        <div class="mt-6">
          <span class="block w-full rounded-md shadow-sm">
            <button
              type="submit"
              class="bg-blue-600 text-white text-center w-full px-4 py-2 rounded-lg transition duration-150 ease-in-out focus:outline-none"
            >
              <span v-if="!performing">Sign in</span>
              <span v-if="performing"
                ><i class="fa fa-circle-notch fa-spin"></i
              ></span>
            </button>
          </span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DotPulse from "@/components/Loaders/DotPulse";

export default {
  name: "AuthLogin",
  data() {
    return {
      performing: false,
      email: null,
      password: null,
      remember: false,

      error: false,
    };
  },
  components: { DotPulse },
  methods: {
    ...mapActions("auth", ["performLogin"]),
    async login() {
      this.performing = true;
      try {
        await this.performLogin({
          email: this.email,
          password: this.password,
          remember: this.remember
        });

        this.$router.replace(this.$route.query.redirect || "/app-redirect");

        this.performing = false;
        this.error = false;
      } catch (e) {
        console.log(e);
        this.performing = false;
        this.error = true;
      }
    },
  },
};
</script>
